/* global tns */

function getTranslateX(obj) {
	const transform = obj.style.transform;
	let split = transform.split('(');
	split = split[1].split('px');

	return parseInt(split[0], 10);
}

document.addEventListener('DOMContentLoaded', () => {
	const sls = [];

	function watchSliders(query) {
		const sliders = Array.from(document.querySelectorAll('.carousel-js'));
		if (query.matches) {
			sliders.map((slider) => {
				const sl = tns({
					container: slider,
					items: 3,
					slideBy: 1,
					autoplay: true,
					gutter: 10,
					fixedWidth: 340,
					nav: true,
					navPosition: 'bottom',
					controls: false,
					autoplayTimeout: 7000,
				});
				sls.push(sl);
				return null;
			});
		} else if (sls !== undefined) {
			sls.forEach((slider, index) => {
				slider.destroy();
				delete sls[index];
			});
		}
	}

	const query = window.matchMedia('(max-width: 1024px)');
	query.addListener(watchSliders);
	watchSliders(query);

	if (document.querySelector('.slider-js')) {
		var quoteSlider = tns({
			container: document.querySelector('.slider-js'),
			items: 1,
			slideBy: 1,
			autoplay: true,
			nav: true,
			navPosition: 'bottom',
			autoplayTimeout: 7000,
			autoHeight: true,
			startIndex: 1
		});
	}

	if (document.querySelector('.logo-list-js')) {
		const prev = document.querySelector('.logo-list-prev-js');
		const next = document.querySelector('.logo-list-next-js');
		const logoList = document.querySelector('.logo-list-js');
		const logos = document.querySelectorAll('.logo-list-image-wrapper-js');
		let slideInterval = setInterval(nextSlide, 8000);

		const nextSlide = () => {
			clearInterval(slideInterval);
			slideInterval = setInterval(nextSlide, 8000);
			const firstSlide = logoList.children[0];
			const slideToHide = logoList.children[1];
			const lastSlide = logoList.lastElementChild;
			const newLeft = parseInt(lastSlide.offsetLeft, 10) + parseInt(lastSlide.offsetWidth, 10);
			const newTranslate = getTranslateX(slideToHide) - slideToHide.offsetWidth;

			logos.forEach((logo) => {
				logo.style.transform = `translateX(${newTranslate}px)`;
			});

			logoList.appendChild(firstSlide);
			firstSlide.style.left = `${newLeft}px`;
		};

		const prevSlide = () => {
			clearInterval(slideInterval);
			slideInterval = setInterval(nextSlide, 8000);
			const firstSlide = logoList.children[0];
			const lastSlide = logoList.lastElementChild;
			const newLeft = parseInt(firstSlide.offsetLeft, 10) - parseInt(lastSlide.offsetWidth, 10);
			const newTranslate = getTranslateX(firstSlide) + firstSlide.offsetWidth;

			logos.forEach((logo) => {
				logo.style.transform = `translateX(${newTranslate}px)`;
			});

			logoList.insertBefore(lastSlide, logoList.firstChild);
			lastSlide.style.left = `${newLeft}px`;
		};

		prev.addEventListener('click', (e) => {
			e.preventDefault();
			prevSlide();
		});

		next.addEventListener('click', (e) => {
			e.preventDefault();
			nextSlide();
		});

		logos.forEach((logo) => {
			// console.log(logo.offsetLeft);
			const newLeft = parseInt(logo.offsetLeft, 10) - logoList.children[0].offsetWidth;
			logo.style.transform = 'translateX(0px)';
			logo.style.left = `${newLeft}px`;
		});

		logos.forEach((logo) => {
			// console.log(logo.offsetLeft);
			logo.style.position = 'absolute';
		});
	}
});
